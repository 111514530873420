.quality-low {
    color: red;
}

.quality-medium {
    color: orange;
}

.quality-high {
    color: green;
}
